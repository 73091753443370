.App {
  background-color: rgb(71, 100, 117);
  width: 100%;
  height: 100%;
  line-height: 1.42857143;
}

.App a {
  text-decoration: none !important;
}

.select-box-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  left: 0px;
}

.select-box {
  background-color: #ffffff;
  border: none;
  width: 290px;
  max-width: 350px;
  margin: 0 auto;
  padding: 3em 35px;
  height: 20em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

hr {
  width: 100%;
  border-color: #fcfcfc;
}
.select-box .select-options {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}

.select-box .select-options .select-title {
  color: white;
  background-color: rgb(71, 100, 117);
  line-height: 1.75em;
  padding: 1em;
  text-align: center;
}

.select-box .select-options .select-options-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}


.select-box .select-options .option-container {
  align-items: center;
  position: relative;
  width: fit-content;
  height: 60px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
}

.select-box .select-options .option-container img {
  height: 100%;
}

.select-box .select-options .logo-img {
  display: inline-block;
  position: relative;
  max-width: 174px;
  max-height: 174px;
  width: 174px;
  height: 174px;
  border: 7px solid #e5e6ea;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  zoom: 0.25;
  background-image: url("./yocontribuyo.png");
  justify-self: center;
}

@media (max-width: 480px) {
  .select-box-container .select-box {
    width: 100% !important;
    margin: 0px 0px 0px 0px !important;
    padding: 5px auto 0px auto !important;
  }
}
